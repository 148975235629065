import React from "react";
import LocalizedLink from "./localizedLink";

const GlobalLink = ({ link, ...props }) => {
  if (link?.isExternal) {
    return <a {...props} href={link?.href} target={link?.target} />;
  } else {
    return <LocalizedLink {...props} to={link?.href} />;
  }
};

export default GlobalLink;
