// Only one item MUST have the "default: true" key

module.exports = {
  en: {
    default: true,
    path: `en`,
    basePath: "",
    locale: `en-US`,
    dateFormat: `DD/MM/YYYY`,
    siteLanguage: `English`,
    ogLanguage: `en_US`,
  },
  fr: {
    path: `fr`,
    basePath: "fr",
    locale: `fr-FR`,
    dateFormat: `DD.MM.YYYY`,
    siteLanguage: `Francais`,
    ogLanguage: `fr_FR`,
  },
};
