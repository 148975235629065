import React, { useState } from "react";
import LocalizedLink from "./localizedLink";
import reactStringReplace from "react-string-replace";
import { HiChevronDown } from "react-icons/all";
import { graphql, StaticQuery } from "gatsby";
import { LocaleContext } from "./layout";
import i18n from "../../config/i18n";
import { sendEvent } from "../utils/googleMeasurementProtocol";
import { HiCheck } from "react-icons/hi";
import GlobalLink from "./globalLink";

const Footer = ({ location }) => {
  const [email, setEmail] = useState("");
  const [isSent, setIsSent] = useState(false);

  const { locale } = React.useContext(LocaleContext);

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    window.dataLayer.push({
      event: "newsletter_signup",
      user_data: {
        email_address: email,
      },
    });
    setEmail("");
    setIsSent(true);
  };

  const selectedLanguage = Object.entries(i18n)
    .map(([key, value]) => value)
    .find((value) => value.path === locale);

  const pathList = Object.entries(i18n).map(([key]) => {
    return key;
  });
  let pathName = location.pathname;

  for (const path of pathList) {
    if (pathName.includes(`/${path}/`) || pathName.endsWith(`/${path}`)) {
      pathName = pathName.replace(`/${path}`, "");
    }
  }
  return (
    <StaticQuery
      query={graphql`
        query StrapiFooter {
          allStrapiFooter {
            nodes {
              brandDescription
              logo {
                url
                alternativeText
              }
              categories {
                items {
                  href
                  label
                  target
                  isExternal
                }
                label
              }
              socials {
                icon
                link {
                  href
                  label
                  target
                  isExternal
                }
              }
              newsletter {
                title
                description
                input {
                  label
                  placeholder
                  type
                }
                button
                sentText
              }
              legalLinks {
                href
                target
                label
                isExternal
              }
              legalRight
              locale
            }
          }
        }
      `}
      render={(data) => (
        <>
          <footer
            className="bg-white border-t border-gray-200"
            aria-labelledby="footer-heading"
          >
            <h2 id="footer-heading" className="sr-only">
              Footer
            </h2>
            {data.allStrapiFooter.nodes
              .filter((footer) => footer.locale === locale)
              .map((footer) => (
                <div className="max-w-5xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-0">
                  <div className="xl:grid xl:grid-cols-3 xl:gap-8">
                    <div className="space-y-8 xl:col-span-1">
                      <img
                        className="h-10 w-auto"
                        src={footer.logo?.url}
                        alt={footer.logo?.alternativeText}
                      />
                      <p className="text-gray-400 text-base">
                        {footer.brandDescription}
                      </p>
                      <div className="flex space-x-4">
                        {footer.socials.map((social) => (
                          <a
                            href={social.link?.href}
                            target={social.link?.target}
                            className="text-gray-400 hover:text-gray-500"
                          >
                            <span className="sr-only">
                              {social.link?.label}
                            </span>
                            <div
                              dangerouslySetInnerHTML={{ __html: social.icon }}
                            />
                          </a>
                        ))}
                      </div>
                    </div>
                    <div className="mt-12 grid grid-cols-1 md:grid-cols-4 gap-8 xl:mt-0 xl:col-span-2">
                      {footer.categories.map((category) => (
                        <div>
                          <h3 className="text-base font-medium text-gray-900">
                            {category.label}
                          </h3>
                          <ul role="list" className="mt-4 space-y-4">
                            {category.items.map((item) => (
                              <li>
                                <GlobalLink
                                  link={item}
                                  className="text-sm text-gray-500 hover:text-gray-900"
                                >
                                  {item.label}
                                </GlobalLink>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ))}
                      <div className="">
                        <h3 className="text-base font-medium text-gray-900">
                          Language
                        </h3>
                        <form className="mt-4 sm:max-w-xs">
                          <fieldset className="w-full">
                            <label htmlFor="language" className="sr-only">
                              Language
                            </label>
                            <div className="relative">
                              <select
                                id="language"
                                name="language"
                                className="appearance-none block w-full bg-none bg-white border border-gray-300 rounded-md py-2 pl-3 pr-10 text-base text-gray-900 focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm"
                                defaultValue={selectedLanguage.basePath}
                                onChange={(event) => {
                                  window.location.assign(
                                    `${
                                      event.target.value
                                        ? `${process.env.GATSBY_WEBSITE_URL}/` +
                                          event.target.value
                                        : `${process.env.GATSBY_WEBSITE_URL}`
                                    }${pathName || "/"}`
                                  );
                                }}
                              >
                                {Object.entries(i18n).map(
                                  ([key, value], index) => {
                                    return (
                                      <option
                                        value={value.basePath}
                                        key={index}
                                      >
                                        {value.siteLanguage}
                                      </option>
                                    );
                                  }
                                )}
                              </select>
                              <div className="pointer-events-none absolute inset-y-0 right-0 px-2 flex items-center">
                                <HiChevronDown
                                  className="h-4 w-4 text-gray-400"
                                  aria-hidden="true"
                                />
                              </div>
                            </div>
                          </fieldset>
                        </form>
                      </div>
                    </div>
                  </div>

                  {footer.newsletter && (
                    <div className="border-t border-gray-200 mt-12 pt-12 lg:flex lg:items-center lg:justify-between">
                      <div>
                        <h3 className="text-base font-medium text-gray-900">
                          {footer.newsletter.title}
                        </h3>
                        <p className="mt-2 text-sm text-gray-500">
                          {footer.newsletter.description}
                        </p>
                      </div>

                      {isSent ? (
                        <div className="flex items-center gap-3">
                          <HiCheck className="text-green-700 h-6 w-6" />
                          <p className="text-base text-gray-500 text-center">
                            {footer.newsletter.sentText}
                          </p>
                        </div>
                      ) : (
                        <form
                          className="mt-4 sm:flex sm:max-w-md lg:mt-0"
                          onSubmit={handleSubmitForm}
                        >
                          <label htmlFor="email-address" className="sr-only">
                            {footer.newsletter.input?.label}
                          </label>
                          <input
                            onChange={(e) => setEmail(e.target.value)}
                            type={footer.newsletter.input?.type}
                            name="email-address"
                            id="email-address"
                            autoComplete="email"
                            required
                            className="appearance-none min-w-0 w-full bg-white border border-gray-300 py-2 px-4 text-base rounded-md text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 focus:placeholder-gray-400 sm:max-w-xs"
                            placeholder={footer.newsletter.input?.placeholder}
                          />
                          <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                            <button
                              type="submit"
                              className="w-full bg-cyan-600 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base font-medium text-white hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                            >
                              {footer.newsletter.button}
                            </button>
                          </div>
                        </form>
                      )}
                    </div>
                  )}
                  <div className="mt-12 border-t border-gray-200 pt-8 flex justify-between">
                    <p className="text-base text-gray-400 xl:text-center">
                      {reactStringReplace(footer.legalRight, "{{date}}", () =>
                        new Date().getFullYear()
                      )}
                    </p>
                    <div className="flex items-center gap-5">
                      {footer.legalLinks.map((link) => (
                        <a
                          href={link.href}
                          target={link.target}
                          className="text-base text-gray-500 hover:text-gray-900"
                        >
                          {link.label}  
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
          </footer>
        </>
      )}
    />
  );
};

export default Footer;
