import React from "react";
import { HiInformationCircle } from "react-icons/all";

const Notification = ({ title, description }) => {
  return (
    <div className="rounded-md bg-cyan-50 p-4 mb-10 z-10">
      <div className="flex">
        <div className="flex-shrink-0">
          <HiInformationCircle
            className="h-5 w-5 text-cyan-600"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          {title && (
            <h5 className="text-sm mb-2 font-medium text-cyan-600">{title}</h5>
          )}
          <div className="text-sm text-cyan-600">{description}</div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
