import React from "react";
import { HiVideoCamera } from "react-icons/all";

const VideoNotification = ({ title, description, link }) => {
  return (
    <div className="rounded-md bg-cyan-50 p-4 mb-10 z-10">
      <div className="flex">
        <div className="flex-shrink-0">
          <HiVideoCamera className="h-5 w-5 text-cyan-600" aria-hidden="true" />
        </div>
        <div className="ml-3">
          {title && (
            <h5 className="text-sm mb-2 font-medium text-cyan-600">{title}</h5>
          )}
          <div className="text-sm text-cyan-600">
            <span>{description}</span>
          </div>
          <div className="mt-4">
            <div className="-mx-2 -my-1.5 flex">
              <a
                href={link}
                target="_blank"
                rel="noopener"
                className="bg-cyan-50 px-2 py-1.5 rounded-md text-sm font-medium text-cyan-600 hover:bg-cyan-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-cyan-600"
              >
                Watch it now
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoNotification;
