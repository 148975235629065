import React from "react";
import { LocaleContext } from "./layout";
import translationsFr from "../../config/translations/fr.json";
import translationsEn from "../../config/translations/en.json";

const useTranslations = () => {
  const { locale } = React.useContext(LocaleContext);
  // Query the JSON files in <rootDir>/i18n/translations
  // const { rawData } = useStaticQuery(query);

  // const jsonData = translationsFr;
  //
  // console.log(jsonData);

  if (locale === "fr") {
    return translationsFr;
  }

  if (locale === "en") {
    return translationsEn;
  }

  // Simplify the response from GraphQL
  // const simplified = rawData.edges.map((item) => {
  //   return {
  //     name: item.node.name,
  //     translations: item.node.translations,
  //   };
  // });

  // Only return translations for the current locale
  // const { translations } = simplified.filter((lang) => lang.name === locale)[0];
  //
  // return translations;
};

export default useTranslations;

// const query = graphql`
//   query useTranslations {
//     rawData: allFile(filter: { sourceInstanceName: { eq: "translations" } }) {
//       edges {
//         node {
//           name
//           translations: childTranslationsJson {
//             hello
//             subline
//             backToHome
//           }
//         }
//       }
//     }
//   }
// `;
