import React, { Fragment } from "react";
import useTranslations from "../components/useTranslations";
import { graphql, StaticQuery } from "gatsby";
import {
  HiCursorClick,
  HiDocumentReport,
  HiRefresh,
  HiShieldCheck,
  HiViewGrid,
  HiChartBar,
  HiInformationCircle,
  HiOfficeBuilding,
  HiUserGroup,
  HiGlobeAlt,
  HiBookmarkAlt,
  HiDesktopComputer,
} from "react-icons/all";
import LocalizedLink from "./localizedLink";
import logo from "../../static/images/addingwell_logo_large.svg";
import logoWhite from "../../static/images/addingwell_logo_large_white.svg";
import { Popover, Menu, Transition } from "@headlessui/react";
import { HiMenu } from "react-icons/all";
import { HiChevronDown } from "react-icons/all";
import { HiX } from "react-icons/hi";
import { LocaleContext } from "./layout";
import GlobalLink from "./globalLink";

const solutions = [
  {
    name: "Analytics",
    description:
      "Get a better understanding of where your traffic is coming from.",
    href: "#",
    icon: HiChartBar,
  },
  {
    name: "Engagement",
    description: "Speak directly to your customers in a more meaningful way.",
    href: "#",
    icon: HiCursorClick,
  },
  {
    name: "Security",
    description: "Your customers' data will be safe and secure.",
    href: "#",
    icon: HiShieldCheck,
  },
  {
    name: "Integrations",
    description: "Connect with third-party tools that you're already using.",
    href: "#",
    icon: HiViewGrid,
  },
  {
    name: "Automations",
    description:
      "Build strategic funnels that will drive your customers to convert",
    href: "#",
    icon: HiRefresh,
  },
  {
    name: "Reports",
    description:
      "Get detailed reports that will help you make more informed decisions ",
    href: "#",
    icon: HiDocumentReport,
  },
];

const support = [
  { name: "Contact Us", href: "/contact-us/", icon: HiInformationCircle },
  { name: "Professional Services", href: "#", icon: HiOfficeBuilding },
];
const docs = [
  { name: "Getting started", href: "#", icon: HiInformationCircle },
  { name: "Recipes", href: "#", icon: HiOfficeBuilding },
];
const partner = [
  { name: "Partner with Addingwell", href: "#", icon: HiUserGroup },
  { name: "Build with Addingwell", href: "#", icon: HiGlobeAlt },
];
const resources = [
  { name: "Community", href: "#", icon: HiUserGroup },
  { name: "Partners", href: "#", icon: HiGlobeAlt },
  { name: "Guides", href: "#", icon: HiBookmarkAlt },
  { name: "Webinars", href: "#", icon: HiDesktopComputer },
];
const blogPosts = [
  {
    id: 1,
    name: "Boost your conversion rate",
    href: "#",
    preview:
      "Eget ullamcorper ac ut vulputate fames nec mattis pellentesque elementum.",
    imageUrl:
      "https://images.unsplash.com/photo-1558478551-1a378f63328e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2849&q=80",
  },
  {
    id: 2,
    name: "How to use search engine optimization to drive traffic to your site",
    href: "#",
    preview:
      "Eget ullamcorper ac ut vulputate fames nec mattis pellentesque elementum.",
    imageUrl:
      "https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2300&q=80",
  },
];

const TopMenu = ({ location }) => {
  const { menu } = useTranslations();
  const { locale } = React.useContext(LocaleContext);

  return (
    <StaticQuery
      query={graphql`
        query StrapiMenu {
          allStrapiMenuCategory(sort: { fields: order, order: ASC }) {
            nodes {
              order
              label
              #              menu_items {
              #                label
              #                link {
              #                  label
              #                  href
              #                  target
              #                  isExternal
              #                }
              #              }
              link {
                href
                label
                target
                isExternal
              }
              locale
            }
          }
        }
      `}
      render={(data) => (
        <Popover className="relative bg-gradient-to-r from-teal-500 to-cyan-600 sticky top-0 z-50">
          <div className="flex max-w-5xl mx-auto justify-between items-center px-4 lg:px-0 py-6 sm:px-6 lg:justify-start lg:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <LocalizedLink className="flex items-center gap-3" to="/">
                <span className="sr-only">Addingwell</span>
                <img
                  className="h-6 w-auto sm:h-8"
                  src={logoWhite}
                  alt="Addingwell logo"
                />
              </LocalizedLink>
            </div>
            <div className="-mr-2 -my-2 lg:hidden">
              <Popover.Button className="rounded-md p-2 inline-flex items-center justify-center text-white hover:text-gray-100">
                <span className="sr-only">Open menu</span>
                <HiMenu className="text-white h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
            <Popover.Group
              as="nav"
              className="hidden lg:flex items-center space-x-10"
            >
              {data.allStrapiMenuCategory.nodes
                .filter((category) => category.locale === locale)
                .map((category) => (
                  <>
                    {category.link && (
                      <GlobalLink
                        link={category.link}
                        className="text-base font-medium text-white"
                        class="hover-underline-animation"
                      >
                        {category.label}
                      </GlobalLink>
                    )}
                    {category.menu_items?.length > 0 && (
                      <Popover className="relative">
                        {({ open, close }) => {
                          return (
                            <>
                              <Popover.Button className="text-white group inline-flex items-center text-sm font-medium focus:outline-none">
                                <span>{category.label}</span>
                                <HiChevronDown
                                  className="text-white ml-2 h-4 w-4"
                                  aria-hidden="true"
                                />
                              </Popover.Button>

                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0 translate-y-1"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 translate-y-1"
                              >
                                <Popover.Panel className="absolute z-10 -ml-4 mt-3 transform">
                                  <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden relative mx-auto flex flex-col lg:flex-row">
                                    <nav className="gap-y-10 px-4 py-4 w-40 bg-white sm:gap-x-8">
                                      <div>
                                        <ul
                                          role="list"
                                          className="m-2 space-y-6"
                                        >
                                          {category.menu_items.map((item) => (
                                            <li
                                              key={item.label}
                                              className="flow-root"
                                            >
                                              <GlobalLink
                                                to={item.link}
                                                onClick={close}
                                                className="-m-3 cursor-pointer px-3 py-2 flex items-center rounded-md text-sm text-gray-500 hover:bg-gray-50"
                                              >
                                                {item.label}
                                              </GlobalLink>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    </nav>
                                    {/*<div className="bg-gray-50 w-2/3 px-4 py-8 sm:py-8 sm:px-6 lg:px-8 xl:pl-12">*/}
                                    {/*  <div>*/}
                                    {/*    <h3 className="text-sm font-medium text-gray-900">*/}
                                    {/*      Last recipes*/}
                                    {/*    </h3>*/}
                                    {/*    <ul*/}
                                    {/*      role="list"*/}
                                    {/*      className="mt-6 space-y-6"*/}
                                    {/*    >*/}
                                    {/*      {blogPosts.map((post) => (*/}
                                    {/*        <li*/}
                                    {/*          key={post.id}*/}
                                    {/*          className="flow-root"*/}
                                    {/*        >*/}
                                    {/*          <a*/}
                                    {/*            href={post.href}*/}
                                    {/*            className="-m-3 p-3 flex rounded-lg hover:bg-gray-100"*/}
                                    {/*          >*/}
                                    {/*            <div className="hidden sm:block flex-shrink-0">*/}
                                    {/*              <img*/}
                                    {/*                className="w-32 h-20 object-cover rounded-md"*/}
                                    {/*                src={post.imageUrl}*/}
                                    {/*                alt=""*/}
                                    {/*              />*/}
                                    {/*            </div>*/}
                                    {/*            <div className="w-0 flex-1 sm:ml-8">*/}
                                    {/*              <h4 className="text-base font-medium text-gray-900 truncate">*/}
                                    {/*                {post.name}*/}
                                    {/*              </h4>*/}
                                    {/*              <p className="mt-1 text-sm text-gray-500">*/}
                                    {/*                {post.preview}*/}
                                    {/*              </p>*/}
                                    {/*            </div>*/}
                                    {/*          </a>*/}
                                    {/*        </li>*/}
                                    {/*      ))}*/}
                                    {/*    </ul>*/}
                                    {/*  </div>*/}
                                    {/*  <div className="mt-6 text-sm font-medium">*/}
                                    {/*    <a*/}
                                    {/*      href="#"*/}
                                    {/*      className="text-indigo-600 hover:text-indigo-500"*/}
                                    {/*    >*/}
                                    {/*      {" "}*/}
                                    {/*      View all posts{" "}*/}
                                    {/*      <span aria-hidden="true">&rarr;</span>*/}
                                    {/*    </a>*/}
                                    {/*  </div>*/}
                                    {/*</div>*/}
                                  </div>
                                </Popover.Panel>
                              </Transition>
                            </>
                          );
                        }}
                      </Popover>
                    )}
                  </>
                ))}

              {/*<Popover>*/}
              {/*  {({ open }) => (*/}
              {/*    <>*/}
              {/*      <Popover.Button className="text-white group rounded-md inline-flex items-center text-sm font-medium">*/}
              {/*        <span>Company</span>*/}
              {/*        <HiChevronDown*/}
              {/*          className="text-white ml-2 h-5 w-5"*/}
              {/*          aria-hidden="true"*/}
              {/*        />*/}
              {/*      </Popover.Button>*/}

              {/*      <Transition*/}
              {/*        as={Fragment}*/}
              {/*        enter="transition ease-out duration-200"*/}
              {/*        enterFrom="opacity-0 -translate-y-1"*/}
              {/*        enterTo="opacity-100 translate-y-0"*/}
              {/*        leave="transition ease-in duration-150"*/}
              {/*        leaveFrom="opacity-100 translate-y-0"*/}
              {/*        leaveTo="opacity-0 -translate-y-1"*/}
              {/*      >*/}
              {/*        <Popover.Panel className="absolute z-10 -ml-4 mt-3 transform w-screen max-w-md lg:max-w-5xl lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">*/}
              {/*          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden relative max-w-5xl mx-auto grid grid-cols-1 lg:grid-cols-2">*/}
              {/*            <nav className="grid gap-y-10 px-4 py-8 bg-white sm:grid-cols-2 sm:gap-x-8 sm:py-8 sm:px-6 lg:px-8 xl:pr-12">*/}
              {/*              <div>*/}
              {/*                <h3 className="text-sm font-medium text-gray-900">*/}
              {/*                  Support*/}
              {/*                </h3>*/}
              {/*                <ul role="list" className="mt-5 space-y-6">*/}
              {/*                  {support.map((item) => (*/}
              {/*                    <li key={item.name} className="flow-root">*/}
              {/*                      <a*/}
              {/*                        href={item.href}*/}
              {/*                        className="-m-3 px-3 py-2 flex items-center rounded-md text-sm text-gray-500 hover:bg-gray-50"*/}
              {/*                      >*/}
              {/*                        <item.icon*/}
              {/*                          className="flex-shrink-0 h-5 w-5 text-gray-400"*/}
              {/*                          aria-hidden="true"*/}
              {/*                        />*/}
              {/*                        <span className="ml-3">{item.name}</span>*/}
              {/*                      </a>*/}
              {/*                    </li>*/}
              {/*                  ))}*/}
              {/*                </ul>*/}
              {/*              </div>*/}
              {/*              <div>*/}
              {/*                <h3 className="text-sm font-medium text-gray-900">*/}
              {/*                  Partner*/}
              {/*                </h3>*/}
              {/*                <ul role="list" className="mt-5 space-y-6">*/}
              {/*                  {partner.map((item) => (*/}
              {/*                    <li key={item.name} className="flow-root">*/}
              {/*                      <a*/}
              {/*                        href={item.href}*/}
              {/*                        className="-m-3 px-3 py-2 flex items-center rounded-md text-sm text-gray-500 hover:bg-gray-50"*/}
              {/*                      >*/}
              {/*                        <item.icon*/}
              {/*                          className="flex-shrink-0 h-5 w-5 text-gray-400"*/}
              {/*                          aria-hidden="true"*/}
              {/*                        />*/}
              {/*                        <span className="ml-3">{item.name}</span>*/}
              {/*                      </a>*/}
              {/*                    </li>*/}
              {/*                  ))}*/}
              {/*                </ul>*/}
              {/*              </div>*/}
              {/*            </nav>*/}
              {/*            <div className="bg-gray-50 px-4 py-8 sm:py-8 sm:px-6 lg:px-8 xl:pl-12">*/}
              {/*              <div>*/}
              {/*                <h3 className="text-sm font-medium text-gray-900">*/}
              {/*                  From the blog*/}
              {/*                </h3>*/}
              {/*                <ul role="list" className="mt-6 space-y-6">*/}
              {/*                  {blogPosts.map((post) => (*/}
              {/*                    <li key={post.id} className="flow-root">*/}
              {/*                      <a*/}
              {/*                        href={post.href}*/}
              {/*                        className="-m-3 p-3 flex rounded-lg hover:bg-gray-100"*/}
              {/*                      >*/}
              {/*                        <div className="hidden sm:block flex-shrink-0">*/}
              {/*                          <img*/}
              {/*                            className="w-32 h-20 object-cover rounded-md"*/}
              {/*                            src={post.imageUrl}*/}
              {/*                            alt=""*/}
              {/*                          />*/}
              {/*                        </div>*/}
              {/*                        <div className="w-0 flex-1 sm:ml-8">*/}
              {/*                          <h4 className="text-base font-medium text-gray-900 truncate">*/}
              {/*                            {post.name}*/}
              {/*                          </h4>*/}
              {/*                          <p className="mt-1 text-sm text-gray-500">*/}
              {/*                            {post.preview}*/}
              {/*                          </p>*/}
              {/*                        </div>*/}
              {/*                      </a>*/}
              {/*                    </li>*/}
              {/*                  ))}*/}
              {/*                </ul>*/}
              {/*              </div>*/}
              {/*              <div className="mt-6 text-sm font-medium">*/}
              {/*                <a*/}
              {/*                  href="#"*/}
              {/*                  className="text-indigo-600 hover:text-indigo-500"*/}
              {/*                >*/}
              {/*                  {" "}*/}
              {/*                  View all posts{" "}*/}
              {/*                  <span aria-hidden="true">&rarr;</span>*/}
              {/*                </a>*/}
              {/*              </div>*/}
              {/*            </div>*/}
              {/*          </div>*/}
              {/*        </Popover.Panel>*/}
              {/*      </Transition>*/}
              {/*    </>*/}
              {/*  )}*/}
              {/*</Popover>*/}
            </Popover.Group>
            <div className="hidden lg:flex items-center justify-end lg:flex-1 lg:w-0">
              <a
                href={process.env.GATSBY_APP_URL}
                target="_blank"
                rel="noopener"
                className="whitespace-nowrap text-sm font-bold text-white hover:text-gray-100"
              >
                Log in
              </a>
              <a
                href={`${process.env.GATSBY_APP_URL}/signup`}
                target="_blank"
                rel="noopener"
                className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-gray-900 bg-white hover:bg-gray-100"
              >
                Start free trial
              </a>
            </div>
          </div>

          <Transition
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="absolute top-0 z-50 inset-x-0 p-2 transition transform origin-top-right lg:hidden"
            >
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                <div className="pt-5 pb-6 px-5">
                  <div className="flex items-center justify-between">
                    <div>
                      <LocalizedLink className="flex items-center gap-3" to="/">
                        <span className="sr-only">Addingwell</span>
                        <img
                          className="h-8 w-auto"
                          src={logo}
                          alt="Addingwell logo"
                        />
                      </LocalizedLink>
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500">
                        <span className="sr-only">Close menu</span>
                        <HiX className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="mt-10">
                    <nav className="grid grid-cols-1 gap-6">
                      {data.allStrapiMenuCategory.nodes
                        .filter((category) => category.locale === locale)
                        .map((category) => (
                          <>
                            {category.link && (
                              <GlobalLink
                                link={category.link}
                                className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50 text-base font-medium text-gray-900"
                              >
                                {category.label}
                              </GlobalLink>
                            )}
                          </>
                        ))}
                    </nav>
                  </div>
                </div>
                <div className="py-6 px-5">
                  <div className="mt-2">
                    <a
                      href={`${process.env.GATSBY_APP_URL}/signup`}
                      target="_blank"
                      rel="noopener"
                      className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-cyan-600 hover:bg-cyan-700"
                    >
                      Sign up
                    </a>
                    <p className="mt-6 text-center text-base font-medium text-gray-500">
                      Existing customer?{" "}
                      <a
                        href={process.env.GATSBY_APP_URL}
                        target="_blank"
                        rel="noopener"
                        className="text-cyan-600 hover:text-cyan-500"
                      >
                        Sign in
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
      )}
    />

    // <div className="bg-gradient-to-r from-teal-500 to-cyan-600 sticky top-0 z-50">
    //   <Menu as="div">
    //     <div className="max-w-6xl mx-auto px-4 sm:px-8 lg:px-16">
    //       <div className="flex justify-between border-b border-gray-200 border-opacity-20 items-center py-6 lg:space-x-6">
    //         <div className="flex items-center ">
    //           <LocalizedLink to="/">
    //             <span className="sr-only">Addingwell</span>
    //             <img
    //               className="h-6 w-auto sm:h-8"
    //               src={logoWhite}
    //               alt="Addingwell logo"
    //             />
    //           </LocalizedLink>
    //         </div>
    //         <div className="flex items-center">
    //           <nav className="hidden lg:flex space-x-6">
    //             <LocalizedLink
    //               to="/why/"
    //               className="text-sm font-medium text-white hover:text-gray-100"
    //             >
    //               {menu.why}
    //             </LocalizedLink>
    //             <LocalizedLink
    //               to="/pricing/"
    //               className="text-sm font-medium text-white hover:text-gray-100"
    //             >
    //               {menu.pricing}
    //             </LocalizedLink>
    //             <LocalizedLink
    //               to="/playbook/"
    //               className="text-sm font-medium text-white hover:text-gray-100"
    //             >
    //               {menu.playbook}
    //             </LocalizedLink>
    //             <LocalizedLink
    //               to="/recipes/"
    //               className="text-sm font-medium text-white hover:text-gray-100"
    //             >
    //               {menu.recipes}
    //             </LocalizedLink>
    //           </nav>
    //         </div>
    //         <div className="-mr-2 -my-2 lg:hidden">
    //           <Menu.Button
    //             type="button"
    //             className="rounded-md p-2 inline-flex items-center justify-center text-white hover:text-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
    //             aria-expanded="false"
    //           >
    //             <span className="sr-only">Open menu</span>
    //             <HiOutlineMenu className="h-7 w-7" />
    //           </Menu.Button>
    //         </div>
    //         <div className="hidden lg:flex items-center justify-end">
    //           <a
    //             href={process.env.GATSBY_APP_URL}
    //             target="_blank"
    //             rel="noopener"
    //             className="whitespace-nowrap text-sm font-bold text-white hover:text-gray-100"
    //           >
    //             Log in
    //           </a>
    //           <a
    //             href={`${process.env.GATSBY_APP_URL}/signup`}
    //             target="_blank"
    //             rel="noopener"
    //             className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-gray-900 bg-white hover:bg-gray-100"
    //           >
    //             Start free trial
    //           </a>
    //           <div className="ml-5">
    //             <LanguageSwitch location={location} />
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //
    //     <Transition
    //       as={Fragment}
    //       enter="transition ease-out duration-100"
    //       enterFrom="transform opacity-0 scale-95"
    //       enterTo="transform opacity-100 scale-100"
    //       leave="transition ease-in duration-75"
    //       leaveFrom="transform opacity-100 scale-100"
    //       leaveTo="transform opacity-0 scale-95"
    //     >
    //       <Menu.Items className="absolute top-0 inset-x-0 z-10 p-2 transition transform origin-top-right lg:hidden">
    //         <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
    //           <div className="pt-5 pb-6 px-5">
    //             <div className="flex items-center justify-between">
    //               <div>
    //                 <LocalizedLink to="/">
    //                   <img
    //                     className="h-8 w-auto"
    //                     src={logo}
    //                     alt="Addingwell Logo"
    //                   />
    //                 </LocalizedLink>
    //               </div>
    //               <div className="-mr-2">
    //                 <Menu.Button
    //                   type="button"
    //                   className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
    //                 >
    //                   <span className="sr-only">Close menu</span>
    //                   <HiOutlineX className="h-6 w-6" />
    //                 </Menu.Button>
    //               </div>
    //             </div>
    //             <div className="mt-6">
    //               <nav className="grid gap-y-8">
    //                 <Menu.Item>
    //                   <LocalizedLink
    //                     to="/"
    //                     className="-m-3 p-3 flex text-base font-medium text-gray-900 items-center rounded-md hover:bg-gray-50"
    //                   >
    //                     {menu.home}
    //                   </LocalizedLink>
    //                 </Menu.Item>
    //                 <Menu.Item>
    //                   <LocalizedLink
    //                     to="/why/"
    //                     className="-m-3 p-3 flex text-base font-medium text-gray-900 items-center rounded-md hover:bg-gray-50"
    //                   >
    //                     {menu.why}
    //                   </LocalizedLink>
    //                 </Menu.Item>
    //                 <Menu.Item>
    //                   <LocalizedLink
    //                     to="/pricing/"
    //                     className="-m-3 p-3 flex text-base font-medium text-gray-900 items-center rounded-md hover:bg-gray-50"
    //                   >
    //                     {menu.pricing}
    //                   </LocalizedLink>
    //                 </Menu.Item>
    //                 <Menu.Item>
    //                   <LocalizedLink
    //                     to="/playbook/01-server-side-tagging/"
    //                     className="-m-3 p-3 flex text-base font-medium text-gray-900 items-center rounded-md hover:bg-gray-50"
    //                   >
    //                     {menu.playbook}
    //                   </LocalizedLink>
    //                 </Menu.Item>
    //                 <Menu.Item>
    //                   <LocalizedLink
    //                     to="/recipes/"
    //                     className="-m-3 p-3 flex text-base font-medium text-gray-900 items-center rounded-md hover:bg-gray-50"
    //                   >
    //                     {menu.recipes}
    //                   </LocalizedLink>
    //                 </Menu.Item>
    //               </nav>
    //             </div>
    //           </div>
    //           <div className="py-6 px-5 space-y-6">
    //             <div className="flex items-center justify-between md:flex-1 lg:w-0">
    //               <div className="mr-3">
    //                 <LanguageSwitch location={location} />
    //               </div>
    //               <div className="flex items-center justify-end">
    //                 <a
    //                   href={process.env.GATSBY_APP_URL}
    //                   target="_blank"
    //                   rel="noopener"
    //                   className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900"
    //                 >
    //                   Log in
    //                 </a>
    //                 <a
    //                   href={`${process.env.GATSBY_APP_URL}/signup`}
    //                   target="_blank"
    //                   rel="noopener"
    //                   className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-cyan-600 hover:bg-cyan-700"
    //                 >
    //                   Start free trial
    //                 </a>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </Menu.Items>
    //     </Transition>
    //   </Menu>
    // </div>
  );
};

export default TopMenu;
