import React from "react";
import { MDXProvider } from "@mdx-js/react";
import MdxLink from "./mdxLink";
import TopMenu from "./menu";
import Footer from "./footer";
import VideoNotification from "./playbook/comp/videoNotification";
import Notification from "./playbook/comp/notification";

const LocaleContext = React.createContext();

const shortcodes = { Notification, VideoNotification, a: MdxLink };

// Use the built-in Context API to make the "locale" available to every component in the tree
// This e.g. enables the LocalizedLink to function correctly
// As this component wraps every page (due to the wrapPageElement API) we can be sure to have
// the locale available everywhere!
const Layout = ({ children, pageContext: { locale }, location }) => {
  return (
    <LocaleContext.Provider value={{ locale }}>
      <div className="relative bg-white">
        <TopMenu location={location} />

        <MDXProvider components={shortcodes}>
          <main>{children}</main>
        </MDXProvider>

        <Footer location={location} />
      </div>
    </LocaleContext.Provider>
  );
};

export { Layout, LocaleContext };
